import React from 'react';
import { useInView } from 'react-intersection-observer';
import NewsletterSignup from './NewsletterSignup';
import Popup from './Popup';
import './App.css';

function Section({ children, className, reverse }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section
      ref={ref}
      className={`${className} ${reverse ? 'reverse' : ''} ${inView ? 'visible' : ''}`}
    >
      {children}
    </section>
  );
}

function ChatBubble({ children, className }) {
  return (
    <div className={`chat-bubble-container`}>
      <div className={`chat-bubble ${className}`}>
        {children}
      </div>
    </div>
  );
}

const faqs = [
  {
    question: "What makes 'Bible Way' different from other Bible apps?",
    answer: "'Bible Way' is like Instagram for the Bible, offering stories, images, and a Bible tracker with engaging and interactive features."
  },
  {
    question: "Can I personalize my experience in the app?",
    answer: "Absolutely! You can select your name, age, email, and denomination, as well as customize the app's color to fit your preferences."
  },
  {
    question: "What can I do with the Bible tracker?",
    answer: "Track your reading progress by marking chapters you've read, and enjoy the satisfaction of seeing how much you've completed, with a confetti of stars when you finish a section!"
  },
  {
    question: "How does the Daily Devotional work?",
    answer: "Each day, you'll receive a devotional about the daily verse, and you can ask questions or seek further insights with our built-in chatbot."
  },
  {
    question: "What is the Art of the Day feature?",
    answer: "Every day, discover new artwork inspired by the Bible, complete with the relevant Bible story, bringing scripture to life in a visual way."
  },
  {
    question: "Can I explore Bible stories in a fun way?",
    answer: "Yes! Scroll through stories of your favorite biblical characters, heroes, and major events, presented like Instagram stories with fun and colorful visuals."
  },
  {
    question: "Is there a way to ask questions about specific Bible verses?",
    answer: "Definitely! You can ask about any verse using the chatbot, which provides instant answers and deeper insights."
  },
  {
    question: "What reading plans are available?",
    answer: "Our reading plans cover a wide range of topics like Love, Family, and more. Each plan offers a 7-day interactive experience with verses, reflections, prayers, and chatbot-guided questions."
  },
  {
    question: "Can I highlight and share verses?",
    answer: "Yes, you can highlight your favorite verses, copy them, and share them with friends or on social media directly from the app."
  },
  {
    question: "What’s special about the Bible reading experience in 'Bible Way'?",
    answer: "The Bible reading experience is enhanced with amazing art from famous artists, making the scriptures come to life as you read."
  },
  {
    question: "What are the upcoming features in 'Bible Way'?",
    answer: "We’re excited to introduce an Audio Bible, Reels and Videos of Bible stories, Biographies of biblical characters, and Maps in future updates."
  },
  {
    question: "How does the Bible Chat feature work?",
    answer: "Our Bible chatbot is your companion for various topics, helping you create prayers, devotionals, and providing faith support."
  },
  {
    question: "What are some suggested topics for the Bible Chat?",
    answer: "Topics include Seeking Comfort, Birth and Early Life of Jesus, and more. You can explore and ask questions on a wide range of spiritual subjects."
  },
  {
    question: "How can I keep track of my favorite verses?",
    answer: "With the 'My Verses' feature, you can easily see all your highlighted verses in one place, making it simple to revisit and reflect."
  },
  {
    question: "Is there a way to engage with Bible stories interactively?",
    answer: "Yes, our Daily Question feature presents a new question every day, and you can discuss your answers with the chatbot for a deeper understanding."
  },
  {
    question: "Can I read both the Old and New Testaments in the app?",
    answer: "Of course! 'Bible Way' provides the full Bible, including both the Old and New Testaments, with tools to enhance your reading experience."
  },
  {
    question: "What is the My Journey screen?",
    answer: "The My Journey screen is your personalized space to track your progress, customize your profile, and manage your app experience."
  },
  {
    question: "Can I upgrade to a premium version?",
    answer: "Yes, the app offers a premium upgrade that unlocks additional features and enhances your overall experience."
  },
  {
    question: "How does the confetti of stars feature work?",
    answer: "After completing a chapter, tap 'I’ve Finished Reading' to celebrate your accomplishment with a fun confetti of stars animation!"
  },
  {
    question: "Why should I download 'Bible Way'?",
    answer: "'Bible Way' offers a unique, interactive, and visually engaging way to experience the Bible, with features that support your spiritual growth and make daily scripture reading a joyful journey."
  }
];

function FAQSection() {
  return (
    <div className="section7">
      <div className="text-container">
        <h2 style={{ marginBottom: '50px', marginTop: '50px' }}>
          Frequently Asked Questions <br /> ❓
        </h2>
        {faqs.map((faq, index) => (
          <div key={index}>
            <ChatBubble className="question">
              <div>{faq.question}</div>
            </ChatBubble>
            <ChatBubble className="answer">
              <div>{faq.answer}</div>
            </ChatBubble>
          </div>
        ))}
      </div>
    </div>
  );
}



function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="logo"><img src={`${process.env.PUBLIC_URL}/logo192.png`} style={{width: '40px', height: '40px', borderRadius: '10px'}}/> Bible Way</div>
        <NewsletterSignup
        triggerButton={(openOverlay) => (
          <button
            onClick={openOverlay}
            style={{
              backgroundColor: '#6390e4',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              fontSize: '16px',
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
          >
            Join Newsletter!
          </button>
        )}
      />      </header>

      <div style={{backgroundColor: '#e9dac2'}}>
      <Section className="section1 section">
        <div className="text-container" style={{display: 'flex', flexDirection: 'column'}}>
          <h1 className="animated-text">
            Experience the Bible <br /> in a whole new way<br />✨
          </h1>
          {/* <button className="download-button">Download Now</button> */}
          {/* <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`} alt="Download on the App Store" style={{width: '200px'}} className="popup-appstore" />
        </a> */}
        <NewsletterSignup
        triggerButton={(openOverlay) => (
          <img  onClick={openOverlay} src={`${process.env.PUBLIC_URL}/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`} alt="Download on the App Store" style={{width: '200px',  cursor: 'pointer' }} className="popup-appstore" />
        )}
      />
        </div>

<div className="image-container image-fader">
<img src={`${process.env.PUBLIC_URL}/David Story.png`}  className="app-image" alt="Bible Way on iPhone" />

<img src={`${process.env.PUBLIC_URL}/Ruth Story.png`} className="app-image" alt="Bible Way on iPhone" />

  <img src={`${process.env.PUBLIC_URL}/instabible.png`} className="app-image" alt="Bible Way on iPhone" />

</div>

      </Section>

      <Section className="section2 section" reverse>
      <div className="text-container animated-text">
          <h2>
            The Ultimate <br /> Bible Tracker<br />📅 
          </h2>
        </div>
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/Bible Tracker.png`} className="app-image" alt="Ultimate Bible Tracker" />
        </div>
      </Section>

      <Section className="section3 section">
        <div className="text-container animated-text">
          <h2>
             Read the Bible <br /> with amazing all-time art<br />🎨
          </h2>
        </div>
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/Bible Art Tower.png`} className="app-image" alt="Bible with amazing all-time art" />
        </div>
      </Section>

      <Section className="section4 section" reverse>
      <div className="text-container animated-text">
          <h2>
             Bible Chat <br /> about any topic<br />💬
          </h2>
        </div>
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/Bible Chat Screen.png`} className="app-image" alt="Bible Chat" />
        </div>
       
      </Section>
      

      <Section className="section5 section">
        <div className="text-container animated-text">
          <h2>
             Scroll through the stories <br /> of our heroes & heroines<br />🦸‍♂️🦸‍♀️
          </h2>
        </div>
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/Bible Stories.png`} className="app-image" alt="Scroll through the stories of our heroes & heroines" />
        </div>
      </Section>


      <Section className="section6 section" reverse>
      <div className="text-container animated-text">
          <h2>
             Add some Colors to your favorite words! <br/> 🎨 
          </h2>
        </div>
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/Verses.png`} className="app-image" alt="Bible Chat" />
        </div>
       
      </Section>
      </div>

      <FAQSection />

      <Popup/>

      <footer className="footer">
        <div>Copyright © 2024 Holy Bible Apps Ltd</div>
        <a className="footer-link" href="mailto:hi+websitemail@bible-way.com?subject=Hello%20👋">Contact</a>
        <a className="footer-link" href="/terms-of-use.html" >Terms of Use</a>
        <a className="footer-link" href="/privacy-policy.html" >Privacy Policy</a>
      </footer>
    </div>
  );
}

export default App;
