import React, { useState } from 'react';

// Reusable logic to handle opening and closing the overlay
const useOverlay = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const openOverlay = () => {
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  return { showOverlay, openOverlay, closeOverlay };
};

const NewsletterSignup = ({ triggerButton }) => {
  const { showOverlay, openOverlay, closeOverlay } = useOverlay();

  return (
    <div style={{ textAlign: 'center', zIndex: 1000000 }}>
      {/* You can pass in any button as a trigger */}
      {triggerButton(openOverlay)}

      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            onClick={closeOverlay}
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              fontSize: '32px',
              cursor: 'pointer',
              background: 'transparent',
              border: 'none',
              color: '#333',
              zIndex: 1001,
              padding: '10px',
            }}
          >
            &times;
          </button>
          <iframe
            src="https://56eb3b5e.sibforms.com/serve/MUIFAA-oBhBmZT3-L6tN0JTrraOxUk3dJ5xvHvgSbu_05REHYYltKfUzmnCjrrK2FMQcuGjNJy5cKiHn_67Q0Wb28bLhX27D-XvIltX2aVonfF-0UlqjZW2tGEyZxKw_RJ3Yof27C0nx0JLKQ4gvAsqVe7zgTMPk5TcSGUR4LAVS7UMPWYCT_ItZCP9xBRJbnLPT3NZos0lAQH-V"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{
              width: '90%',
              height: '80%',
              border: 'none',
              borderRadius: '20px',
            }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default NewsletterSignup;


  //  // src="https://56eb3b5e.sibforms.com/serve/MUIFAAyG8zc1Z6dt2C8fL6UrYLXNhh6XJSB1tTaWo391ljPeVsaocQjz8VO4p-KLVLzIEjfx5koI5B6ogc625uXgL33GodZUuyKOQOsFml_cx5RDq9ErcL2qdCs3TuGHhSIIoOYcrhzFthNhjw8f2bQIlmY3WzxHN3-2nXYsfCR_Uk8eJa3UxoNsGRy7tZFdV0pbBQ8EYupShs-8"
  //  src="https://56eb3b5e.sibforms.com/serve/MUIFAA-oBhBmZT3-L6tN0JTrraOxUk3dJ5xvHvgSbu_05REHYYltKfUzmnCjrrK2FMQcuGjNJy5cKiHn_67Q0Wb28bLhX27D-XvIltX2aVonfF-0UlqjZW2tGEyZxKw_RJ3Yof27C0nx0JLKQ4gvAsqVe7zgTMPk5TcSGUR4LAVS7UMPWYCT_ItZCP9xBRJbnLPT3NZos0lAQH-V"
